import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ChangePwdModal } from '../../../shared/modals/common';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';

@Component({
  selector: 'app-rp-changepassword',
  templateUrl: './rp-changepassword.component.html',
  styleUrl: './rp-changepassword.component.scss',
})
export class RpChangepasswordComponent {
  changePwdForm!: UntypedFormGroup;
  submitted: any = false;
  error: any = '';
  success: any = '';
  loading: any = false;

  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  fieldTextType!: boolean;
  newPwdfieldTextType!: boolean;
  oldPwdfieldTextType!: boolean;
  mailId: any;
  respMessage: any;
  changePWdValidations = Validations.ChangePwdPage;
  // tslint:disable-next-line: max-line-length
  constructor(
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private sharedService: SharedService
  ) {}

  reset() {}

  ngOnInit() {
    this.changePwdForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  ngAfterViewInit() {}

  // convenience getter for easy access to form fields
  get f() {
    return this.changePwdForm.controls;
  }

  closeModal() {
    this.modalService.hide();
  }
  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleNewPwdFieldTextType() {
    this.newPwdfieldTextType = !this.newPwdfieldTextType;
  }
  toggleConfirmPwdFieldTextType() {
    this.oldPwdfieldTextType = !this.oldPwdfieldTextType;
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;

    // stop here if form is invalid
    if (
      this.changePwdForm.invalid &&
      this.f['confirmPassword'].value != this.f['newPassword'].value
    ) {
      return;
    }
    this.loaderService.setLoading(true);
    let payload: ChangePwdModal = {
      oldPassword: this.f['oldPassword'].value,
      newPassword: this.f['newPassword'].value,
      confirmPassword: this.f['confirmPassword'].value,
    };
    this.authService.updatePassword(payload).subscribe({
      next: (res) => {
        console.log(res);
        if (
          res.statusCode == 200 &&
          res.message == 'Password reset successful.'
        ) {
          this.otpSuccessMsg = true;
          this.otpErrorMsg = false;
          this.respMessage = res;
          this.sharedService.showMessageDialog(
            SweetAlertIcon.SUCCESS,
            res.message,
            SweetAlertIcon.ERROR
          );
        } else {
          this.otpErrorMsg = true;
          this.otpSuccessMsg = false;
          this.sharedService.showMessageDialog(
            SweetAlertIcon.ERROR,
            res.message,
            SweetAlertIcon.ERROR
          );
          this.respMessage = res;
          this.loaderService.setLoading(false);
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.log('err', err);
      },
    });
    // if (environment.defaultauth === 'firebase') {
    //   this.authenticationService.resetPassword(this.f.email.value)
    //     .catch(error => {
    //       this.error = error ? error : '';
    //     });
    // }
  }
}
