<app-rp-loader></app-rp-loader>
<div bsModal tabindex="-1">
    <!-- <div class="modal-dialog modal-dialog-centered"> -->
    <div class="modal-content">
        <div class="modal-header bg-primary text-white">
            <h5 class="modal-title">Change password</h5>
            <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
            <form class="form-horizontal" [formGroup]="changePwdForm" (ngSubmit)="onSubmit()"
                (keyup.enter)="onSubmit()">
                <div class="mb-3">
                    <label>Enter Old Password</label>
                    <div class="input-group auth-pass-inputgroup">
                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                            formControlName="oldPassword" class="form-control" id="oldPassword"
                            placeholder="Old Password" />
                        <button class="btn btn-light ms-0" type="button" id="password-addon">
                            <i class="mdi mdi-eye-outline"
                                [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"
                                (click)="toggleFieldTextType()"></i></button>
                        @if(submitted && f['oldPassword'].errors){
                        <div class="invalid-feedback">
                            @if(f['oldPassword'].errors['required']){
                            <div>{{changePWdValidations.oldPwd_required_Validations}}</div>}
                        </div>}
                    </div>
                </div>

                <div class="mb-3">
                    <label>Enter New Password</label>
                    <div class="input-group auth-pass-inputgroup">
                        <input [type]="newPwdfieldTextType ? 'text' : 'password'" class="form-control"
                            formControlName="newPassword" class="form-control" id="newPassword"
                            placeholder="New Password" />
                        <button class="btn btn-light ms-0" type="button" id="password-addon">
                            <i class="mdi mdi-eye-outline"
                                [ngClass]="{'mdi-eye-off-outline': !newPwdfieldTextType, 'mdi-eye-outline': newPwdfieldTextType}"
                                (click)="toggleNewPwdFieldTextType()"></i></button>
                        @if(submitted && f['newPassword'].errors){
                        <div class="invalid-feedback">
                            @if(f['newPassword'].errors['required']){
                            <div>{{changePWdValidations.newPwd_required_Validations}}</div>}
                        </div>}
                    </div>
                </div>

                <div class="mb-3">
                    <label>Re-enter New Password</label>
                    <div class="input-group auth-pass-inputgroup">
                        <input [type]="oldPwdfieldTextType ? 'text' : 'password'" class="form-control"
                            formControlName="confirmPassword" class="form-control" id="confirmPassword"
                            placeholder="Confirm Password" />
                        <button class="btn btn-light ms-0" type="button" id="password-addon">
                            <i class="mdi mdi-eye-outline"
                                [ngClass]="{'mdi-eye-off-outline': !oldPwdfieldTextType, 'mdi-eye-outline': oldPwdfieldTextType}"
                                (click)="toggleConfirmPwdFieldTextType()"></i></button>
                        @if(submitted && f['confirmPassword'].errors){
                        <div class="invalid-feedback">
                            @if(f['confirmPassword'].errors['required']){
                            <span>{{changePWdValidations.confirmPwd_required_validations}}</span>
                            }
                            @if(f['confirmPassword'].value != f['newPassword'].value){
                            <span>{{changePWdValidations.match_required_validations}}</span>
                            }

                        </div>}

                        <div class="invalid-feedback">
                            <span
                                *ngIf="f['confirmPassword'].value != f['newPassword'].value">{{changePWdValidations.match_required_validations}}</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
            <button type="button" class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">Save</button>
        </div>
    </div><!-- /.modal -->
</div>
<!-- </div> -->